<template>
  <fragment>
    <div class="mb-5"><h1>Request To Create New Company</h1></div>

    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div class="ml-0">
            <h2 class="m-0 mb-2">Company Information</h2>
          </div>
          <div>
            <span class="is-required mr-2"></span> = Required
          </div>
        </div>

        <div class="form-group">
          <label for="companyName">
            Company Name
            <span class="is-required ml-3"></span>
          </label>
          <input
            id="companyName"
            name="companyName"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.has('companyName') }"
            autocomplete="off"
            placeholder="Company Name"
            v-validate="'required'"
            v-model="company.name"
            data-vv-as="Company Name"
          />
          <div class="invalid-feedback">The Company Name field is required</div>
        </div>

        <div class="form-group">
          <label for="companyEmail">Company Email</label>
          <input
            id="companyEmail"
            name="companyEmail"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.has('companyEmail') }"
            autocomplete="off"
            placeholder="Email"
            v-model="company.email"
            v-validate="'email'"
            data-vv-as="Company Email"
          />
          <div class="invalid-feedback">{{ errors.first('companyEmail') }}</div>
        </div>

        <div class="form-group">
          <label for="website">Website</label>
          <input
            id="website"
            name="website"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.has('website') }"
            autocomplete="off"
            placeholder="Website"
            v-model="company.website"
            v-validate="'url'"
            data-vv-as="Website"
          />
          <div class="invalid-feedback">{{ errors.first('website') }}</div>
        </div>

        <div class="form-group">
          <label for="address">
            Address
            <span class="is-required ml-3"></span>
          </label>
          <input
            id="address"
            name="address"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.has('address') }"
            autocomplete="off"
            placeholder="Address"
            v-validate="'required'"
            v-model="company.street"
            data-vv-as="Address"
          />
          <div class="invalid-feedback">The Address field is required</div>
        </div>

        <div class="form-row">
          <div class="col-md-4 mb-3">
            <label for="city">
              City
              <span class="is-required ml-3"></span>
            </label>
            <input
              id="city"
              name="city"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.has('city') }"
              autocomplete="off"
              placeholder="City"
              v-validate="'required'"
              v-model="company.city"
              data-vv-as="City"
            />
            <div class="invalid-feedback">The City field is required</div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="country">
              Country
              <span class="is-required ml-3"></span>
            </label>
            <select
              id="country"
              name="country"
              class="custom-select"
              v-model="company.country"
              :class="{ 'is-invalid': errors.has('country') }"
              v-validate="'required'"
              data-vv-as="Country"
              v-on:change="filterProvinces"
            >
              <option v-for="country in countryList" :key="country.id" :value="country.id">{{ country.name }}</option>
            </select>
            <div class="invalid-feedback">The Country field is required</div>
          </div>
          <div class="col-md-4 mb-3">
            <label for="province">
              Province/State
              <span class="is-required ml-3"></span>
            </label>
            <select
              id="province"
              name="province"
              class="custom-select"
              :class="{ 'is-invalid': errors.has('province') }"
              v-validate="'required'"
              v-model="company.province"
              data-vv-as="Province"
            >
              <option v-for="province in provinceList" :key="province.id" :value="province.abbreviation">{{ province.name }}</option>
            </select>
            <div class="invalid-feedback">The Province/State field is required</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-md-4 mb-3">
            <label for="postalCode">Postal Code</label>
            <input
              id="postalCode"
              name="postalCode"
              type="text"
              class="form-control"
              autocomplete="off"
              placeholder="Postal Code"
              v-model="company.postalCode"
            />
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="phone">Phone</label>
            <input
              id="phone"
              name="phone"
              type="text"
              class="form-control"
              autocomplete="off"
              placeholder="Phone"
              v-model="company.phone"
            />
          </div>
          <div class="col-md-6 mb-3">
            <label for="fax">Fax</label>
            <input
              id="fax"
              name="fax"
              type="text"
              class="form-control"
              autocomplete="off"
              placeholder="Fax"
              v-model="company.fax"
            />
          </div>
        </div>

        <div class="form-group form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="companyDirectoryListMe"
            v-model="company.listInDirectory"
          />
          <label class="form-check-label" for="companyDirectoryListMe">List me in the directory</label>
        </div>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col-md-12">
        <div class="d-flex justify-content-between align-items-center mb-4">
          <div class="ml-0">
            <h2 class="m-0 mb-2">User Information</h2>
          </div>
          <div>
            <span class="is-required mr-2"></span> = Required
          </div>
        </div>

        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="userFirstName">
              First name
              <span class="is-required ml-3"></span>
            </label>
            <input
              id="userFirstName"
              name="userFirstName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.has('userFirstName') }"
              autocomplete="off"
              placeholder="Enter first name"
              v-validate="'required|max:255'"
              v-model="user.firstName"
              data-vv-as="First Name"
            />
            <div class="invalid-feedback">{{ errors.first('userFirstName') }}</div>
          </div>

          <div class="col-md-6 mb-3">
            <label for="userLastName">
              Last name
              <span class="is-required ml-3"></span>
            </label>
            <input
              id="userLastName"
              name="userLastName"
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.has('userLastName') }"
              autocomplete="off"
              placeholder="Enter Last name"
              v-validate="'required|max:255'"
              v-model="user.lastName"
              data-vv-as="Last Name"
            />
            <div class="invalid-feedback">{{ errors.first('userLastName') }}</div>
          </div>
        </div>

        <div class="form-group">
          <label for="userEmail">
            Email
            <span class="is-required ml-3"></span>
          </label>
          <input
            id="userEmail"
            name="userEmail"
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.has('userEmail') }"
            autocomplete="off"
            placeholder="Enter email address"
            v-validate="'required|email'"
            v-model="user.email"
            data-vv-as="Email"
          />
          <div class="invalid-feedback">{{ errors.first('userEmail') }}</div>
        </div>

        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="userPassword">
              Password
              <span class="is-required ml-3"></span>
            </label>
            <input
              id="userPassword"
              name="userPassword"
              type="password"
              class="form-control"
              :class="{ 'is-invalid': errors.has('userPassword') }"
              autocomplete="off"
              placeholder="Enter password"
              v-validate="{ required: true, min:8, max:50 }"
              v-model="user.password"
              data-vv-as="Password"
              ref="password"
            />
            <div class="invalid-feedback">{{ errors.first('userPassword') }}</div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="userPasswordConfirm">
              Confirm Password
              <span class="is-required ml-3"></span>
            </label>
            <input
              id="userPasswordConfirm"
              name="userPasswordConfirm"
              type="password"
              class="form-control"
              :class="{ 'is-invalid': errors.has('userPasswordConfirm') }"
              autocomplete="off"
              placeholder="Enter Password Confirmation"
              v-validate="'required|confirmed:password'"
              v-model="user.passwordConfirm"
              data-vv-as="Confirm Password"
            />
            <div class="invalid-feedback">'Password' and 'Confirm Password' must match.</div>
          </div>
        </div>

        <div class="form-group form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="userEmailOptOut"
            v-model="user.emailOptOut"
          />
          <label class="form-check-label" for="userEmailOptOut">Opt out from daily email</label>
        </div>

        <div class="form-group form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="userDirectoryListAsContact"
            v-model="user.listAsDirectoryContact"
          />
          <label
            class="form-check-label"
            for="userDirectoryListAsContact"
          >List me as directory contact</label>
        </div>
      </div>
    </div>

    <hr class="mt-0" />

    <div class="row">
      <div class="col-md-12">
        <div class="form-group form-check">
          <input
            type="checkbox"
            id="tosAccept"
            name="tosAccept"
            class="form-check-input"
            :class="{ 'is-invalid': errors.has('tosAccept') }"
            v-model="user.tosAccept"
            v-validate="'required'"
            data-vv-as="Terms of Service Acceptance"
          />
          <label class="form-check-label" for="tosAccept">
            I agree to the
            <a
              :href="tosUrl"
              title="Terms of Service"
              tabindex="-1"
              target="_blank"
            >terms of service</a>
          </label>
          <div class="invalid-feedback">You must agree before submitting.</div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<script>
import { mapActions } from "vuex";
import { alpha as alphaSort } from "@/utils/sort";
export default {
  name: "CompanyCreateStepInfo",
  inject: ["$validator"],
  props: ["company", "user"],
  data: function () {
    return {
      provinceList: null,
      fullProvinceList:null,
      countryList: null,
      tosUrl: process.env.VUE_APP_TEMPLATE_SIGNUP_TOS,
    }
  },
  mounted:function(){
    this.getCountries();
    this.getProvinces();    
  },
  methods:{
    ...mapActions(["fetchProvinces","fetchCountries"]),
    getCountries(){
      this.fetchCountries().then((result)=>{
        this.countryList =  result;
      });
    },
    getProvinces(){
      this.fetchProvinces().then((result)=>{
        this.fullProvinceList =  result;
        this.filterProvinces();
      });
    },
    filterProvinces() {
      this.provinceList = this.fullProvinceList.filter((p)=>{return p.countryId == this.company.country}).sort((a, b) => alphaSort(a.name, b.name));
    }
  }
};
</script>

<style lang="sass" scoped>
.is-required:after
    content: "*"
    color: red
    font-size: 18px
    font-weight: bold
</style>
