<template>
  <div>
    <form class="mb-3" @submit.prevent>
      <component :is="this.steps[this.currentStep]"
        :company="company" :localAssociationId.sync="localAssociationId" :plans="plans" :planId.sync="planId" :user="user" :zones="zones"
        @update-plan="onUpdatePlanSelection"></component>

      <div class="d-flex flex-row-reverse bd-highlight">
        <button type="button" class="btn btn-success ld-ext-right" :class="{ 'running' : isSubmitting }" :disabled="isSubmitting" @click="nextStep">
          <span v-if="isSubmitting">Submitting...</span>
          <span v-else>Next</span>
          <div class="ld ld-ring ld-spin"></div>
        </button>
        <button type="button" class="btn btn-primary mr-2" @click="backStep">Back</button>
      </div>
    </form>
  </div>
</template>

<script>
import { CompanyService, MembershipPlanService, ZoneService } from '@/services/';
import StepInfo from '@/views/public/components/company-create-step-info.vue';
import StepPlan from '@/views/public/components/company-create-step-plan.vue';
import Toast from "@/utils/toast";

export default {
  name: 'public-company-create',
  data: function () {
    return {
      company: {
        city: null,
        email: null,
        fax: null,
        listInDirectory: true,
        name: null,
        phone: null,
        country: null,
        postalCode: null,
        province: null,
        street: null,
        website: null
      },
      user: {
        email: null,
        emailOptOut: false,
        firstName: null,
        lastName: null,
        listAsDirectoryContact: true,
        password: null,
        tosAccept: false
      },

      currentStep: 0,
      error: null,
      isCompleted: false,
      isSubmitting: false,
      localAssociationId: null,
      planId: null,
      plans: [],
      steps: [StepInfo, StepPlan],
      zones: []
    }
  },
  computed: {
    isFirstStep () {
      return this.currentStep === 0;
    },
    isLastStep () {
      return this.currentStep === this.steps.length - 1;
    }
  },
  methods: {
    backStep () {
      if (this.currentStep === 0) {
        this.$router.push({ path: '/signup' });
      }
      else if (this.currentStep === 1) {
        this.currentStep = this.currentStep - 1;
        this.$validator.validate();
      }
    },
    cancel () {
      this.$router.push({ name: 'signup-company-lookup' });
    },
    async nextStep () {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid)
        return;

      if (this.currentStep === 0) {
          this.currentStep = this.currentStep + 1;
      }
      else if (this.currentStep === 1)
        await this.onSubmit();
    },
    async onSubmit() {
      this.error = null;
      this.isCompleted = false;

      var isFormValid = await this.$validator.validate();
      if (!isFormValid || this.isCompleted) {
        return;
      }

      this.isSubmitting = true;

      let payload = {
        company: this.company,
        localAssociationId: this.localAssociationId,
        planId: this.planId,
        user: this.user
      };

      CompanyService.signupCreate(payload).then(({ headers, data }) => {
          this.isSubmitting = false;
          let location = headers.location;

          this.$store.dispatch('testAuth', data);

          if (location) {
            window.location.href = location;
          }
      })
      .catch(() => {
        this.isSubmitting = false;
        Toast.danger(this, "Oops! An error has occured.");
      });
    },
    onUpdatePlanSelection: function (selection) {
      if (selection.type === 'plan') {
        this.planId = selection.id;
        this.localAssociationId = null;
      }
      else if (selection.type === 'association') {
        this.planId = null;
        this.localAssociationId = selection.id;
      }
    },
  },
  async mounted () {
    this.company.name = this.$store.state.global.publicSignupVerifyCompany || null;
    this.user.email = this.$store.state.global.publicSignupVerifyEmail || null;

    var payload = await Promise.all([
      MembershipPlanService.get(),
      ZoneService.getAllPublic()
    ])

    this.plans = payload[0].data;
    this.zones = payload[1].data;

    this.$validator.reset();
  }
}
</script>
