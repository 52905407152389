<template>
  <div class="row">
    <div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto">
      <h1>Select a Plan</h1>
      <p class="lead">
        Link2Build is a member-only electronic plans room service.  We provide over 12,000 bidding opportunities across Ontario every year. Check out our pricing plans:
      </p>
    </div>

    <div class="container">
      <div class="row mb-3">
        <div class="card mb-4 shadow-sm col-12 col-sm-6 col-md-4 col-lg-3" :class="{ 'selectedPlan': internalLocalAssocationId !== null }">
          <div class="mt-3 mb-3">
            <h4 class="my-0 font-weight-normal text-center">Partner Associations</h4>
          </div>
          <div class="card-body">
            <div class="full-top">
              <p>Join for complete annual access to all the features and benefits from our partner Associations</p>
              <ul class="list-unstyled mb-4">
                <li class="mb-1">
                  <div class="d-flex">
                    <div class="mr-1"> &#x2713; </div>
                    <div class="flex-fill"> View Projects </div>
                  </div>
                </li>
                <li class="mb-1">
                  <div class="d-flex">
                    <div class="mr-1"> &#x2713; </div>
                    <div class="flex-fill"> Bid Calendar </div>
                  </div>
                </li>
                <li class="mb-1">
                  <div class="d-flex">
                    <div class="mr-1"> &#x2713; </div>
                    <div class="flex-fill"> Opportunity Matching </div>
                  </div>
                </li>
                <li class="mb-1">
                  <div class="d-flex">
                    <div class="mr-1"> &#x2713; </div>
                    <div class="flex-fill"> Advanced Search </div>
                  </div>
                </li>
                <li class="mb-1">
                  <div class="d-flex">
                    <div class="mr-1"> &#x2713; </div>
                    <div class="flex-fill"> Unlimited Full Project Access per Association </div>
                  </div>
                </li>
                <li class="mb-1">
                  <div class="d-flex">
                    <div class="mr-1"> &#x2713; </div>
                    <div class="flex-fill"> Planroom Project Documents </div>
                  </div>
                </li>
                <li class="mb-1">
                  <div class="d-flex">
                    <div class="mr-1"> &#x2713; </div>
                    <div class="flex-fill"> Project & Document Update Notifications </div>
                  </div>
                </li>
                <li class="mb-1">
                  <div class="d-flex">
                    <div class="mr-1"> &#x2713; </div>
                    <div class="flex-fill"> Tender Awards </div>
                  </div>
                </li>
              </ul>
              <hr />
              <div class="form-group">
                <label for="exampleFormControlSelect1" class="text-center">Local Associations</label>
                <select class="custom-select" id="exampleFormControlSelect1" v-model="internalLocalAssocationId">
                  <option v-for="zone in zones" :key="'zone-' + zone.id" :value="zone.id">{{ zone.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="card mb-4 shadow-sm col-12 col-sm-6 col-md-4 col-lg-3" :class="{ 'selectedPlan': plan.id === planId }" v-for="plan in plans" :key="'plan-' + plan.id">
          <div class="mt-3 mb-3">
            <h4 class="my-0 font-weight-normal text-center">{{ plan.name }}</h4>
          </div>
          <div class="card-body">
            <div class="full-top">
              <p v-if="plan.saleDetails && plan.saleDetails.opener">{{ plan.saleDetails.opener }}</p>
              <ul class="list-unstyled mb-4" v-if="plan.saleDetails && plan.saleDetails.points">
                <li class="mb-2" v-for="(item, index) in plan.saleDetails.points" :key="'plan-' + plan.id + '-blurb-' + index">
                  <div class="d-flex">
                    <div class="mr-1"> &#x2713; </div>
                    <div class="flex-fill"> {{ item }} </div>
                  </div>
                </li>
              </ul>
              <hr />
            </div>
            <h2 class="card-title pricing-card-title mb-4 text-center">
              {{ plan.price | currency }}
              <small class="text-muted">/{{ renderDuration(plan.duration) }}</small>
            </h2>
            <button type="button" class="btn btn-sm btn-block btn-outline-primary mb-4" :class="{ 'active': plan.id === planId }" @click="selectPlan(plan.id)">
              {{ planId === plan.id ? 'Selected' : 'Select Plan' }}
            </button>
            <a v-if="plan.externalLink" target="_blank" :href="plan.externalLink">Plan Details &#10148;</a>
          </div>
        </div>
      </div>

      <input type="hidden" name="hasPlanId" :class="{ 'is-invalid': errors.has('hasPlanId') }" v-model="hasSelectedPlan" v-validate="'required:true'" />
      <div class="invalid-feedback">Please select a plan</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyCreateStepPlan",
  inject: ["$validator"],
  props: ["company", "plans", "user", "zones", "localAssociationId", "planId"],
  computed: {
    hasSelectedPlan: function () {
      return this.localAssociationId !== null || this.planId !== null;
    },
    internalLocalAssocationId: {
      get: function () {
        return this.localAssociationId;
      },
      set: function (value) {
        this.$emit("update-plan", { type: 'association', id: value });
      }
    }
  },
  methods: {
    renderDuration(timeframe) {
      if (timeframe === 12) return 'Year'
      return `${ timeframe } months`
    },
    selectPlan: function(planId) {
      this.$emit("update-plan", { type: 'plan', id: planId });
    }
  }
};
</script>

<style scoped>
  .selectedPlan {
    border: 2px #303030 solid;
  }
  .card-body {
    display: flex;
    flex-direction: column;
  }

  .card-body .full-top {
    flex-grow: 1;
  }
</style>
